/* =============== Importation de Google Fonts =============== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* =============== Réinitialisation des styles =============== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

/* ================= Espacement entre les sections ================= */
section {
  margin-bottom: 20px; /* Ajoute un espace horizontal de 20px entre chaque section */
}

/* ================= HERO SECTION ================= */
.hero-section {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  transition: background-image 1s ease-in-out;
  position: relative;
}

.hero-text-container {
  background: rgba(255, 255, 255, 0.2);
  padding: 20px 40px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
}

.hero-title {
  font-size: 5rem;
  font-weight: 700;
  color: #272727;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
}

.hero-subtitle {
  font-size: 2rem;
  font-weight: 400;
  color: #272727;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
}

/* ================= SECTION LE CONCEPT ================= */
#concept {
  background-color: #ffffff;
  padding: 50px;
}


#concept .col-md-5 {
  position: relative;
  background-image: url('../assets/images/concept.jpg');
  background-size:contain;
  background-repeat: no-repeat;
  background-position: left;
  background-attachment: fixed;
  min-height: 500px;
  border-top-left-radius: 80px 80px;
}

#concept .section-title {
  text-align: left;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #ef8525;
}

#concept .section-subtitle {
  text-align: center;
  margin-bottom: 20px;
}


/* ================= SECTION À QUI S’ADRESSE LE CONCEPT ================= */
#packs {
  background-color: #e0e0e0;
  padding: 80px 0;
}

#packs .section-title {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #2c3e50;
}

#packs .section-subtitle {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 50px;
}

.concept-card {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.concept-card i {
  font-size: 3rem;
}

.concept-icon {
  transition: color 0.3s ease; /* Pour une transition fluide */
}

.concept-card:hover .concept-icon {
  color: #ff0000; /* Remplacez par la couleur souhaitée */
}

.concept-card h5 {
  font-weight: bold;
}

/* ================= SECTION L’AVANTAGE DE LA LOCATION ================= */
#advantages {
  padding: 80px 0;
  background-color: transparent !important;
}

#advantages .section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-weight: bold;
  color: #2980b9;
}

#advantages .section-subtitle {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 50px;
}

.advantages-list {
  list-style: none;
  padding: 0;
}

.advantages-list li {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.advantages-list li::before {
  content: "✔";
  color: #007bff;
  font-weight: bold;
  position: absolute;
  left: 0;
}

/* ================= SECTION NOTRE ENGAGEMENT ================= */
#engagement {
  background-color: #343a40;
  padding: 50px 0;
  color: #f8f9fa;
}

#engagement .section-title {
  font-size: 2.5rem;
  text-align: left;
  margin-bottom: 40px;
  font-weight: bold;
}

.engagement-image {
  max-width: 70%;
  height: auto;
  display: block;
  margin: auto;
}

.engagement-list {
  list-style: none;
  padding: 0;
}

.engagement-list li {
  font-size: 1rem;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  padding-left: 30px;
  padding-right: 50px;
}

.engagement-list li::before {
  content: "✔";
  color: #17a2b8;
  font-weight: bold;
  position: absolute;
  left: 0;
}

/* ================= RESPONSIVE DESIGN ================= */
@media (max-width: 991px) {
  .row.equal-height > * {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .hero-text-container {
    background: none;
  }

  #concept .col-md-5 {
    background-attachment: scroll;
    display: none;
  }

  .row.equal-height > * {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .engagement-image {
    max-width: 90%;
  }

  #engagement .row {
    flex-direction: column-reverse;
  }
}

/* ================= SECTION RÉFÉRENTS ================= */
#referents {
  padding: 50px 0;
  text-align: center;
}

#referents .section-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
}

.referent-logo {
  max-width: 150px;
  height: auto;
  margin: 10px auto;
}

/* Responsive pour les petits écrans */
@media (max-width: 768px) {
  .referent-logo {
    max-width: 120px;
  }
}


/* Effet hover sur les liens de la Navbar */
.navbar-nav .nav-link {
  font-size: 1rem;
  color: #333; /* Couleur par défaut */
  transition: all 0.3s ease-in-out; /* Animation fluide */
}

/* Effet hover : gras + icônes pleines */
.navbar-nav .nav-link:hover {
  font-weight: bold; /* Texte en gras */
  text-decoration:underline; /* Supprime le soulignement */
  color: #000; /* Changer la couleur (facultatif) */
}

.navbar-nav .nav-link:hover i {
  font-weight: bold;
}

/* Modification des icônes en version 'fill' au survol */
.navbar-nav .nav-link:hover .bi-info-circle::before { content: "\F335"; } /* Remplace icône par sa version pleine */
.navbar-nav .nav-link:hover .bi-box-seam::before { content: "\F7D3"; }
.navbar-nav .nav-link:hover .bi-bag::before { content: "\F174"; }
.navbar-nav .nav-link:hover .bi-question-circle::before { content: "\F504"; }
.navbar-nav .nav-link:hover .bi-envelope::before { content: "\F32C"; }


/* Footer */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #f8f9fa; /* Fond clair */
  border-top: 1px solid #ddd; /* Bordure supérieure */
}

.footer-text {
  font-size: 1rem;
  color: #333;
  margin: 0;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-link {
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease-in-out;
}

.social-link:hover {
  color: #007bff; /* Bleu Bootstrap */
}

/* Footer fixé en bas de la page */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
} 

/* ================= RESPONSIVE DESIGN ================= */

@media (max-width: 991px) {
  .row.equal-height > * {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .hero-text-container {
    background: none;
  }

  #concept .col-md-5 {
    background-attachment: scroll;
  }

  .row.equal-height > * {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .concept-image {
    display: none;
  }

  .engagement-image {
    max-width: 90%;
  }

  #engagement .row {
    flex-direction: column-reverse;
  }
  
  .pack-card {
    margin-bottom: 40px;
  }
  #packs {
    padding-top: 120px;

  }
}