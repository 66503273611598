.products-container {
    margin-top: 100px; /* Espace entre la Navbar et le titre */
  }
  
  .products-title {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: #ef8525;
    margin-bottom: 50px;
  }
  
  .products-header {
    margin-bottom: 20px; /* Espace entre le nom du dossier et les photos */
  }
  