/* ================= SECTION NOS PACKS ================= */
#packs {
  background-color: transparent;
  padding: 20px 0 50px;  /* Réduction du padding-top de 100px à 20px */
  margin-bottom: 60px;
}

#packs .section-title {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #ef8525;
  margin-bottom: 20px;
}

.banner {
  margin-bottom: 30px; /* Conserve un margin-bottom nul pour la bannière */
}

.banner .banner-title {
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

#packs p {
  margin-bottom: 3rem;  /* Augmentation de l'espace sous le paragraphe pour séparer le texte des cartes */
  font-size: large;
}

#packs .section-subtitle {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 50px;
  font-weight: bold;
}

.banner .banner-subtitle {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 50px;
  color: #333;
}

.pack-card {
  background: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
  border-color: #ef8525;
}

.pack-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  border-color: #ef8525;
}

/* Organisation en 4 sections */
.pack-card .pack-section {
  flex: 1;
  padding: 10px 20px;
}

.pack-card .pack-header {
  flex: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pack-card .pack-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 0px;
}

.pack-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #007bff;
  margin-bottom: 0px;
  text-decoration:underline #7f8c8d;
  text-decoration-thickness: 5px;
}

.pack-card h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 0px;
  margin-top:8px;
}

/* Section 2 : Liste descriptive avec icône + */
.pack-card .pack-description {
  flex: 20%;
  font-size: 0.8rem;
  color: #555;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
}

.pack-card .pack-description p {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pack-card .pack-description p::before {
  content: "+"; /* Icône Bootstrap plus-circle */
  color: #007bff;
  font-size: 1rem;
  font-weight: bold;
}

/* Section 3 : Liste du contenu alignée à gauche et top vertical */
.pack-card .pack-content {
  flex: 50%;
  text-align: left;
  padding: 40px;
  font-size: large;
}

.pack-card .pack-content ul {
  list-style: none;
  padding: 0;

}

.pack-card .pack-content ul li {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
  padding-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.pack-card .pack-content ul li i {
  font-size: 1.2rem;
  color: #007bff;
}

/* Section 4 : Prix & Durée */
.pack-card .pack-pricing {
  flex: 15%;
  text-align: center;
}

.pack-card .pack-pricing h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ef8525;
  margin-bottom: 20px;
  margin-top: 20px;
}

.pack-card .pack-pricing ul {
  list-style: none;
  padding: 0;
}

.pack-card .pack-pricing ul li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.pack-card .pack-pricing ul li::before {
  content: "-"; 
  font-weight: bold;
  color: #333;
  padding-right: 5px;
  margin-top: 15px;
}

.pack-card .pack-image {
 margin-bottom: 20px;
}

/* Footer fixé en bas de la page */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
} 

/* ================= RESPONSIVE DESIGN ================= */
@media (max-width: 768px) {
  .pack-card {
    margin-bottom: 40px;
  }
  #packs {
    padding-top: 120px;

  }
}