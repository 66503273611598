.contact-container {
    max-width: 1000px;
    margin: 120px auto 50px; /* Augmente le margin-top pour espacer du Navbar */
    background: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container-title {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #ef8525;
}


  .contact-container label {
    font-weight: bold;
    font-size: large;
  }

  form {
    margin-top: 20px;
  }
  
  .form-control {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 4px;
    margin-bottom: 20px;
  }
  
  .btn-dark {
    width: 100%;
    font-size: 1.2rem;
  }
  
  .success-message {
    color: green;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
/* Section plein écran */
.full-width-section {
    width: 100%;
    margin-bottom: 50px; /* Ajuste cette valeur selon ton besoin */
    padding: 0;
  }
  
  /* Section horaires */
  .horaires-section {
    background: #2e2f35; /* Fond sombre */
    color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    min-height: 300px; /* Assurer une bonne hauteur */
  }
  
  /* Section Google Maps */
  .google-map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .google-map {
    width: 100%;
    height: 100%;
    min-height: 300px;
    border: none;
  }
  
  /* Suppression des marges entre les colonnes */
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  
  /* Responsive : Ajustement sur mobile */
  @media (max-width: 768px) {
    .full-width-section {
      flex-direction: column;
    }
  
    .horaires-section {
      width: 100%;
      padding: 30px;
    }
  
    .google-map {
      height: 300px;
    }
  }
  