/* ================= SECTION FAQ ================= */
#faq {
    background-color: transparent;
    padding: 100px 0 50px;
    margin-bottom: 60px;
  }

   #faq .faq-header {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: #ef8525;
    margin-bottom: 20px;
  }
  
  #faq .faq-subtitle {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 50px;
    font-weight: bold;
  }
  
  .faq-card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    margin-bottom: 0px;
    width: calc(50% -10px);
    border-left: 5px solid #ef8525;
  }
  
  .faq-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .faq-card h3 {
    font-size: 1.2em;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .faq-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
/* Conteneur général avec affichage en grille à deux colonnes */
.faq-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  align-items: flex-start; /* Permet à chaque carte de conserver sa hauteur naturelle */
}

  /* ================= RESPONSIVE DESIGN ================= */
  @media (max-width: 768px) {
    .faq-card {
      padding: 30px;
    }
 
  .faq-content {
    grid-template-columns: 1fr;
  }
}

.faqbanner {
  margin-bottom: 30px; /* Conserve un margin-bottom nul pour la bannière */
}

.faqbanner .banner-title {
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
}

.faqbanner .banner-subtitle {
  text-align: center;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 20px;
}

